import {
    MapContainer,
    Popup,
    TileLayer,
    useMap,
    LayersControl,
    LayerGroup,
    useMapEvent,
    GeoJSON
} from "react-leaflet";

import * as turf from '@turf/turf'
import { Icon, SVG} from "leaflet";
import React, { useRef, useEffect, useState } from "react";
import { useTheme } from '@material-ui/core/styles';

import ReactDOM from 'react-dom'
import {Container} from "@material-ui/core";
import MapBar from "./MapBar";


const NIcon = new Icon.Default()
NIcon.options.iconSize = [20, 30];
NIcon.options.shadowSize = [40,30]


function CloseAreaLayer(props) {
    const { notams, selectedIndex } = props;
    const Polygons = []

    notams.map((notam) => {
            if ('geometry' in notam)  {
                notam.geometry.map((geometry) => {
                    if (!('properties'  in geometry)) { //NEED A SMARTER WAY TO FILTER THIS
                        if (geometry.type === 'Polygon') {
                            Polygons.push({'geometry': geometry, 'notam': notam});
                        }
                    }
                })
            }
        }
    )

    return Polygons.map((item, index) => (
        <AreaPath key={index} item={item} selectedIndex={selectedIndex}/>
    ));
}

function AreaPath(props){
    const pathRef = useRef(null);
    const theme = useTheme();
    const {key, item, selectedIndex} = props

    const closedAreaOptions = { color: 'orange', weight : 4 , opacity: 1, fillOpacity: 0.8}


    const active = (index) => {
        if(selectedIndex.indexOf(index) === -1){
            return false
        } else {
            return true
        }
    }

    useEffect(() => {
        pathRef.current.setStyle({color: (active(item.notam.id)) ? theme.palette.primary.dark :  'orange'})
        pathRef.current.setStyle({fillColor: (active(item.notam.id)) ? theme.palette.primary.light :  'orange'})
        pathRef.current.setStyle({weight: (active(item.notam.id)) ? 15 :  4})
    }, [selectedIndex]);

    return (
        <GeoJSON ref={pathRef} key={key} data={item.geometry} buffer={item.geometry} pathOptions={closedAreaOptions} notam={item.notam}/>
    );

}

function CloseRoutesLayer(props) {
    const { notams, selectedIndex } = props;
    const LineStrings = []
    const Renderer = new SVG({tolerance: 20, padding : 0.5});
    const map = useMap()
    const [zoomLevel, setZoomLevel] = useState(map.getZoom())

    map.on('zoomend', function () {
        setZoomLevel(map.getZoom())
    })

    notams.map((notam) => {
            if ('geometry' in notam)  {

                notam.geometry.map((geometry) => {

                    if (geometry.type === 'LineString') {
                        LineStrings.push({'geometry': geometry, 'notam': notam});
                    }
                })
            }
        }
    )

    return LineStrings.map((item, index) => (
        <RoutePath zoomLevel={zoomLevel} key={index} renderer={Renderer} item={item} map={map} selectedIndex={selectedIndex}></RoutePath>
    ));
}


function RoutePath(props) {
    const pathRef = useRef(null);
    const theme = useTheme();
    const {key, renderer, item, zoomLevel, selectedIndex} = props

    let colorMap = {
        expired : theme.palette.text.disabled,
        active : theme.palette.secondary.main,
        upcoming: theme.palette.warning.main
    }

    let weightMap = {
        6: 2,
        7: 2,
        8: 4,
        9: 6,
        10: 12,
        11: 18,
        12: 25,
        13: 30
    }

    const active = (index) => {
        if(selectedIndex.indexOf(index) === -1){
            return false
        } else {
            return true
        }
    }


    useEffect(() => {
        pathRef.current.setStyle({weight:  weightMap[zoomLevel] - 10})
        pathRef.current.setStyle({opacity: (active(item.notam.id)) ? 1 : 0 })
        pathRef.current.setStyle({color: (active(item.notam.id)) ? theme.palette.primary.main : colorMap[item.notam.status]  })
    }, [zoomLevel, selectedIndex]);

    const inflated = turf.buffer(item.geometry, 1000, {units: 'meters'}) //HEAVY!

    return (
        <GeoJSON ref={pathRef} key={key} notam={item.notam} data={inflated} buffer={inflated} renderer={renderer} pathOptions={{fillOpacity: 0.7}}/>
    );

}

function CloseCircleLayer(props) {
    const { notams, renderer, selectedIndex } = props;
    const Polygons = []
    const theme = useTheme()

    notams.map((notam) => {
            if ('geometry' in notam)  {

                notam.geometry.map((geometry) => {
                    if ('properties' in geometry) {
                        if ('radius_meters' in geometry.properties) {
                            if (geometry.properties.radius_meters <= 10*1852) { //DISCARD CIRCLES OF RADIUS BIGGER THEN 10nm
                                Polygons.push({'geometry': geometry, 'notam': notam});
                            }
                        }
                    }
                })
            }
        }
    )




    return Polygons.map((item, index) => (
        <CirclePath renderer={renderer} key={index} item={item} selectedIndex={selectedIndex}/>
    ));
}


function CirclePath(props) {
    const pathRef = useRef(null);
    const theme = useTheme();
    const {key, renderer, item, selectedIndex} = props
    const CircleAreaOptions = { fillOpacity: 0.8, opacity: 1, color: theme.palette.error.dark, weight: 4, fillColor: theme.palette.error.light}

    const active = (index) => {
        if(selectedIndex.indexOf(index) === -1){
            return false
        } else {
            return true
        }
    }


    useEffect(() => {
        pathRef.current.setStyle({color: (active(item.notam.id)) ? theme.palette.primary.dark :  theme.palette.error.dark})
        pathRef.current.setStyle({fillColor: (active(item.notam.id)) ? theme.palette.primary.light :  theme.palette.error.light})
        pathRef.current.setStyle({weight: (active(item.notam.id)) ? 15 :  4})

    }, [selectedIndex]);

    return (
        <GeoJSON ref={pathRef} renderer={renderer} key={key} data={item.geometry} buffer={item.geometry} pathOptions={CircleAreaOptions} notam={item.notam}/>
    );
}


function WaypointsLayer(props) {
    const { waypoints } = props;

    return waypoints.map((waypoint, index) => (
        <GeoJSON
            key={index}
            data={waypoint}
        >
            <Popup>{waypoint.name}</Popup>
        </GeoJSON>

    ));
}

// function NotamsLayer(props) {
//     const { notams, sel } = props;
//
//
//     const points = [];
//
//     notams.map((notam, index) => (
//         notam.geometry.map((point)=> {
//
//             if (point.geometry.type === 'Point') {
//
//                 points.push({point: point, notam:notam})
//
//             }
//         })
//     ));
//
//
//     return points.map((pnt) => (
//         <NotamMarker
//             id={pnt.notam.index}
//             point={pnt.point}
//             notam={pnt.notam}
//             // openPopup={sel === notam.id}
//         />
//         ))
//


// }


function NotamMarker(props) {
    const markerRef = useRef(null);
    const { notam, openPopup, id, point } = props;
    const map = useMap()


    useEffect(() => {
        var pos = [(notam.lat * 90) / 1073741824,(notam.lon * 90) / 1073741824];
        if (openPopup) map.setView(pos,12);
        if (openPopup) markerRef.current.openPopup();

    }, [openPopup]);

    //console.log(notam.geometry)

    return (
        // <div></div>
        <GeoJSON data={point} key={id} ref={markerRef}  icon={NIcon} data={notam.geometry}/>
    );
}


function RendererOverride(props) {
    const {notams, selectedIndex} = props;
    const map = useMap();
    map.createPane('Circles');
    map.getPane('Circles').style.opacity = '0.5';
    map.getPane('Circles').style.zIndex = '399';
    const renderer = new SVG({pane: 'Circles'});

    return (
        <CloseCircleLayer notams={notams} renderer={renderer} selectedIndex={selectedIndex} />
    )


}

function MapContent(props) {

    const {notams, cvfr_waypoints, ifr_waypoints, lsa_waypoints, setShowNotamDrawer, selectedIndex} = props;

    const circleLayerGroupRef = useRef(null);
    const areaLayerGroupRef = useRef(null);
    const routeLayerGroupRef = useRef(null);

    const mapClickEvent = useMapEvent("click", (e)=>{
        let data = []

        routeLayerGroupRef.current.eachLayer(function(layer){
            var pt = new turf.point([e.latlng['lng'], e.latlng['lat']])
            var pip = turf.booleanPointInPolygon(pt, layer.options.buffer.geometry)
            if(pip){data.push(layer.options.notam)}
        })

        circleLayerGroupRef.current.eachLayer(function(layer){
            var pt = new turf.point([e.latlng['lng'], e.latlng['lat']])
            var pip = turf.booleanPointInPolygon(pt, layer.options.buffer.geometry)
            if(pip){data.push(layer.options.notam)}
        })

        areaLayerGroupRef.current.eachLayer(function(layer){
            //console.log(layer)
            var pt = new turf.point([e.latlng['lng'], e.latlng['lat']])
            var pip = turf.booleanPointInPolygon(pt, layer.options.buffer)
            if(pip){data.push(layer.options.notam)}
        })

        if (data.length >= 1) {
            setShowNotamDrawer(true, data.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i)) //DEDUP: its not my code, and i hate code that is too complicated, but it works.
        } else {
            setShowNotamDrawer(false, [])
        }

    })

    // This is how it should be done...
    // https://codesandbox.io/embed/competent-edison-wt5pl?fontsize=14
    console.log(selectedIndex + ' < selected indices')

    return (
            <LayersControl position="topleft">

                <LayersControl.BaseLayer checked name="ATS">
                    <TileLayer
                        url="https://flight-maps.com/tiles/ifr/{z}/{x}/{y}.png"
                        attribution='liorbenhorin.xyz'

                    />
                </LayersControl.BaseLayer>


                <LayersControl.BaseLayer checked name="LSA">
                    <TileLayer
                        url="https://flight-maps.com/tiles/la/{z}/{x}/{y}.png"
                        attribution='liorbenhorin.xyz'
                    />

                </LayersControl.BaseLayer>

                <LayersControl.BaseLayer checked name="CVFR">
                    <TileLayer
                        url="https://flight-maps.com/tiles/cvfr/{z}/{x}/{y}.png"
                        attribution='liorbenhorin.xyz'

                    />
                </LayersControl.BaseLayer>



                <LayersControl.Overlay  name="CVFR waypoints">
                    <LayerGroup>
                        <WaypointsLayer waypoints={cvfr_waypoints} />
                    </LayerGroup>
                </LayersControl.Overlay>

                <LayersControl.Overlay  name="IFR waypoints">
                    <LayerGroup>
                        <WaypointsLayer waypoints={ifr_waypoints} />
                    </LayerGroup>
                </LayersControl.Overlay>

                <LayersControl.Overlay  name="LSA waypoints">
                    <LayerGroup>
                        <WaypointsLayer waypoints={lsa_waypoints} />
                    </LayerGroup>
                </LayersControl.Overlay>


                {/*<LayersControl.Overlay  name="NOTAM">*/}
                {/*    <LayerGroup>*/}
                {/*        <NotamsLayer selectedIndex={selectedIndex} notams={notams}/>*/}
                {/*    </LayerGroup>*/}
                {/*</LayersControl.Overlay>*/}



                <LayersControl.Overlay checked name="Area NOTAMs">
                    <LayerGroup ref={areaLayerGroupRef}>
                        <CloseAreaLayer notams={notams} selectedIndex={selectedIndex}/>
                    </LayerGroup>
                </LayersControl.Overlay>

                <LayersControl.Overlay checked name="Point/Radius NOTAMs">
                    <LayerGroup ref={circleLayerGroupRef}>
                        <RendererOverride notams={notams} selectedIndex={selectedIndex}/>
                    </LayerGroup>
                </LayersControl.Overlay>

                <LayersControl.Overlay checked name="Route NOTAMs">
                    <LayerGroup ref={routeLayerGroupRef}>
                        <CloseRoutesLayer notams={notams} selectedIndex={selectedIndex}/>
                    </LayerGroup>
                </LayersControl.Overlay>


            </LayersControl>

    );
}


const Overlay = (props) => {
    const map = useMap();

    return ReactDOM.createPortal(
        <MapBar {...props}/>,
        map._container
    );
}


function Map(props) {
    const {setClearAllFilters, total, available, lastUpdate, total_unfiltered, _fetch} = props;

    return (
        <MapContainer center={[32.085300, 34.781769]}
                      zoom={9}
                      maxZoom={13}
                      minZoom={5}
                      zoomControl={false}
                      className={"map"}
                      id='leafletMap'>
            <Overlay setClearAllFilters={setClearAllFilters} total={total} total_unfiltered={total_unfiltered} available={available} lastUpdate={lastUpdate} _fetch={_fetch}/>
            <MapContent {...props}/>

        </MapContainer>
    )
}

export default Map;
