import {makeStyles} from "@material-ui/core/styles";
import React from "react";

import Toolbar from "@material-ui/core/Toolbar";
import {Paper} from "@material-ui/core";
import {Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Map from "./Map";


const useStyles = makeStyles((theme) => ({

        paperDiv:{
            zIndex:1000,
            position:'relative',
            display: 'inline-block',
            justifyContent : 'center',
            width: '100%',
            //width: `calc(100% - 60px)`,
            margin: '0 auto',
            //marginRight: 20,
            // [theme.breakpoints.up('sm')]:{
            //     marginTop: 15,
            //     maxWidth: 'none'
            // },
            //marginTop: 25,
            //maxWidth: 150,

        },
    paper : {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
        padding: theme.spacing(1),
        //borderRadius: 25,
        boxShadow: '4px 3px 5px 2px rgba(255, 105, 135, .3)',
        borderRadius: 0,
    },
    paperUpdateError : {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        padding: theme.spacing(1),
        //borderRadius: 25,
        boxShadow: '4px 3px 5px 2px rgba(255, 105, 135, .3)',
        borderRadius: 0,
    },
    })
)


function MapBar(props) {

    const {setClearAllFilters, total, available, lastUpdate, total_unfiltered, _fetch} = props;

    const classes = useStyles();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);

        //event.stopPropagation()
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const clearAllFilters = () => {
        handleClose()
        setClearAllFilters()
    }

    const checkFetchCurrency = (fetch) => {

        var permittedDifference = 15; // allow 15 minutes of delay

        var curTime = new Date().getTime()

        var diff = ((curTime)/1000) - fetch;
        diff /= 60;
        diff = Math.abs(Math.round(diff));

        if (diff < permittedDifference) { //The update is current!
            return true;
        } else {
            return false;
        }

    };

    return (
        <div>
            <Toolbar/>
            <div className={classes.paperDiv}>
                <Paper
                    button
                    className={checkFetchCurrency(_fetch) ? classes.paper: classes.paperUpdateError}
                    onClick={handleClick}
                >
                    <Typography align='center'>
                        {/*{ _fetch > 100*/}
                        {/*    ? <Box fontWeight="fontWeightBold" fontSize={13} >Valid at {lastUpdate}</Box>*/}
                        {/*    : <Box fontWeight="fontWeightBold" fontSize={13} >Valid at {lastUpdate}</Box>*/}
                        {/*}*/}

                        {checkFetchCurrency(_fetch)
                            ?<Box fontWeight="fontWeightBold" fontSize={13}>Valid at {lastUpdate}</Box>
                            :<Box fontWeight="fontWeightBold" fontSize={13}>REFRESH REQUIRED - valid at {lastUpdate}</Box>
                        }

                        <Box fontWeight="fontWeightBold" fontSize={13}  >Displaying {total} NOTAMS</Box>
                    </Typography>
                </Paper>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin = { { vertical: 'bottom', horizontal: 'center'} }
                    transformOrigin={ { vertical: -50, horizontal: 'center'}}
                >
                    <MenuItem onClick={clearAllFilters}>Clear all filters</MenuItem>
                </Menu>
            </div>
        </div>


    )
}

export default MapBar;