import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import TextNotam from "./TextNotam";
import React, {useState, useMemo, useEffect} from "react";
import AppBar from '@material-ui/core/AppBar';
import {makeStyles, useTheme, fade} from "@material-ui/core/styles";
import {Container} from "@material-ui/core";
//import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Button from "@material-ui/core/Button";
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import ListSubheader from '@material-ui/core/ListSubheader';
import TextField from "@material-ui/core/TextField";
import {Typography} from "@material-ui/core";
import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import { Virtuoso, GroupedVirtuoso } from 'react-virtuoso'
import { NotamHeader, NotamIcon, NotamBody} from './utils'
import Hidden from "@material-ui/core/Hidden";
// import ListItemAvatar from "@material-ui/core";

// import ListItemIcon from "@material-ui/core";
// import Avatar from "@material-ui/core/Avatar";
import {termsMapping} from './utils.js'
import _, { map } from 'underscore';

//https://virtuoso.dev/

const useStyles = makeStyles((theme) => ({
    filterBar : {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2),
        position: 'fixed',
        zIndex: theme.zIndex.drawer + 1,
        // height: 50,
        boxShadow: '0px 2px 5px 2px rgba(0, 0, 0, .1)',
        // zIndex:
        //top:0,
        [theme.breakpoints.up('sm')] :{
            //position: 'relative'
            top: 'auto',

        },
    },
    notamList : {
        [theme.breakpoints.up('sm')] :{
            marginTop: 90
        },
        marginTop: 35,
    },
    appBar: {
        [theme.breakpoints.up('sm')]: {
            width: 450,
            //flexShrink: 0,
        },
        zIndex: theme.zIndex.drawer + 3,
        //marginTop: 63,
        width: '100%',
    },
    toolbar: theme.mixins.toolbar,
    formControl : {
        margin: theme.spacing(1)

        },
    subHeader: {
        padding: theme.spacing(1),
        color:theme.palette.common.white
    },
    subHeaderBar :{
        backgroundColor:theme.palette.primary.main,
        top:95, // CAN WE GET THIS NUMBER DYNAMICLY?
        boxShadow: '0px 2px 5px 2px rgba(210, 118, 25, .3)',
        [theme.breakpoints.up('sm')]:{
            top:160
        },
        // top:0
    },
    virtuosoContainer : {
        height: `calc(100vh - 90px)`,
        // [theme.breakpoints.up('xs')]:{
        //     height: `calc(100vh - 90px)`,
        // },
    },
    virtuosoList :{
        height: 'inherit',
        // [theme.breakpoints.up('sm')]:{
        //     top:90
        // },
        top:90,
    }
})

)



function groupNotams(notams, by) {

    const groups = {}

    notams.map((notam) => {

        if(by === 'FIR'){
            var key = notam.itema.join()
        }


        if(key in groups) {
            groups[key].push(notam)
        } else {
            groups[key] = [notam]
        }
    })

    return groups
}


function NotamGroup(props) {
    const {group, notams} = props;
    const theme = useTheme()
    const classes = useStyles();


    return (
        <div>
            <ListSubheader className={classes.subHeaderBar}>
                <Typography className={classes.subHeader}>
                    <Box fontWeight="fontWeightBold">
                    {group}
                    </Box>
                </Typography>
            </ListSubheader>
            {
                notams.map((notam, index) => (
                    <ListItem>{notam.itema}</ListItem>


                ))
            }
        </div>
    )
}


function NotamPane(props){

    const {notams, handleNotamClick} = props;
    const classes = useStyles();
    const theme = useTheme();

    const [groupBy, setGroupBy] = useState('itema')
    const [filtered, setfiltered] = useState(true)


    const buildNotamGroups = () => {
        let _groups
        let _groupCounts = []
        let _items = []
        let _notams = []

        if(filtered){
            console.log('notam list filter is on')
            notams.map((notam) => {
                let unviewable_notam = false;
                notam.geometry.map((geo)=>{
                    if('properties' in geo){
                        if('radius_meters' in geo.properties){
                            if(geo.properties.radius_meters > 10*1852){
                                unviewable_notam = true;
                            }
                        }
                    }
                })
                if(unviewable_notam){
                    _notams.push(notam)
                }            
            });
        } else {
            console.log('notam list filter is off')
            _notams = notams
        }    


        if(groupBy === 'itema') {
            _groups = new Array(...new Set(_notams.map(notam => notam[groupBy].join())))
        } else {
            _groups = new Array(...new Set(_notams.map(notam => notam[groupBy])))
        }

        if(groupBy === 'itema') {
            _groups.map((grp)=> {
                let count = 0;
                let itemsPreSort = []
                _notams.map((notam)=>{
                    if(notam[groupBy].join()==grp){
                        count+=1
                        itemsPreSort.push(notam)
                    }
                })

                _items.push(..._.sortBy(itemsPreSort, 'modified').reverse())
                _groupCounts.push(count)
            })
        } else {
            _groups.map((grp)=> {
                let count = 0;
                let itemsPreSort = []
                _notams.map((notam)=>{
                    if(notam[groupBy]==grp){
                        count+=1
                        itemsPreSort.push(notam)
                    }
                })

                _items.push(..._.sortBy(itemsPreSort, 'modified').reverse())
                _groupCounts.push(count)
            })
        }

        let parsedGroups = _groups.map(grp => (grp.trim() in termsMapping) ? termsMapping[grp.trim()] : grp)

        let newGroups = {
            groups: parsedGroups,
            counts: _groupCounts,
            items: _items
        }
        
        // console.log('notams in sidepane +++');
        // console.log(_notams)
        return newGroups;
    }

    const [groups, setGroups] = useState(()=>buildNotamGroups())

    useEffect(()=> {
        setGroups(buildNotamGroups(notams, filtered))
    }, [groupBy, filtered, notams])

    const handleGroupChange = (event) => {
        console.log(event.target.value)
        setGroupBy(event.target.value)
    }

    const handleFilteredToggle = (event) => {
        console.log(event.target.value)
        setfiltered(event.target.checked)
    }

    const Components = useMemo(() => {
        return {
            Footer: () => {
                return (
                    <div
                        style={{
                            padding: "1rem",
                            textAlign: "center",
                        }}
                    >
                        No more NOTAMs to show
                    </div>
                );
            },

            List: React.forwardRef(({ style, children }, listRef) => {
                return (
                    <List
                        style={{padding: 0, ...style, margin: 0}}
                        component="div"
                        ref={listRef}
                    >
                        {children}
                    </List>
                );
            }),

            Item: ({ children, ...props }) => {
                return (
                    <ListItem component="div" {...props} style={{ margin: 0 }}>
                        {children}
                    </ListItem>
                );
            },

            Group: ({ children, ...props }) => {
                return (
                    <ListSubheader
                        component="div"
                        {...props}
                        style={{
                            backgroundColor: 'white',
                            margin: 0
                        }}
                        disableGutters={true}
                        disableSticky={true}
                    >
                        {children}
                    </ListSubheader>
                );
            },
        };
    }, [groups]);


    // const _handleNotamClick = (notam) => {
    //     console.log('click');
    //     (notam) => handleNotamClick();
    // }

    // console.log(groups)

    return (
        <div>

            <Hidden xsDown implementation="css">
                {/*<Toolbar />*/}
                <Divider />
            </Hidden>

                <Container className={classes.filterBar}>
                    <div>
                    <FormControl className={classes.formControl}>
                        <InputLabel shrink htmlFor="age-native-label-placeholder">
                            Group by
                        </InputLabel>
                    <Select
                        native
                        value={groupBy}
                        onChange={handleGroupChange}
                        inputProps={{
                            name: 'groupBy',
                            id: 'age-native-simple',
                        }}
                    >
                        <option value={'itema'}>Location</option>
                        <option value={'traffic'}>Flight rules</option>
                        <option value={'scope'}>Scope</option>
                    </Select>
                    </FormControl>

                    {/* <FormControl className={classes.formControl}>
                       <TextField id="standard-basic" label="Search" />
                    </FormControl> */}
                    </div>
                    <div>

                    <FormControlLabel
                    style={{width:'100% !important'}}
                    control={
                        <Switch
                            checked={filtered}
                            onChange={handleFilteredToggle}
                            name="filteredSwitch"
                        />
                    }
                    label={<Typography style={{paddingLeft:10, width:'100%'}}>Show only not visible on map</Typography>}
                    />
                    </div>
                </Container>
                
            <div>
                <div className={classes.virtuosoContainer}>
                <GroupedVirtuoso
                    className={classes.virtuosoList}
                    components={Components}
                    groupCounts={groups.counts}

                    groupContent={index => {
                        return <div
                            style={{
                                backgroundColor: theme.palette.primary.main,
                                color : theme.palette.common.white,
                                paddingTop: '1rem',
                                paddingLeft: 20,
                                boxShadow: '0px 2px 5px 2px rgba(0, 0, 0, .1)'
                                //borderBottom: '1px solid #ccc'
                            }}>
                                <Box fontWeight="fontWeightBold" fontSize={20}>
                                    {groups.groups[index]}
                                </Box>
                        </div>
                    }}

                    itemContent={index => {
                        const notam = groups.items[index]
                        return (
                            <>

                                <ListItemText
                                    primary={ <span><Button style={{width:'100%', justifyContent: 'inherit', textAlign: 'inherit', marginLeft:0, marginRight:0}} onClick={()=>handleNotamClick(notam)}><NotamHeader notam={notam}/></Button></span> }
                                    secondary={ <span><NotamBody notam={notam}/></span> }
                                />
                            </>
                        )
                    }}
                />
                </div>
            </div>
        </div>

    )

}

export default NotamPane;