import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";
import Global from "./Global";
import Login from "./Login";

export default function Routes() {

    return (

        <Switch>
            <Route exact path="/">
                <Global />
            </Route>
            <Route exact path="/login">
                <Login />
            </Route>
        </Switch>

    );
}