import React, {useEffect, useState} from "react";

import {makeStyles, useTheme} from '@material-ui/core/styles';

import Grid from "@material-ui/core/Grid";
import {Button, Container, Typography} from "@material-ui/core";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Drawer from "@material-ui/core/Drawer";

import { NotamHeader, NotamIcon, NotamBody, NotamChips, NotamHeaderHint} from './utils'
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const bottomApBarHeight = 35;

const useStyles = makeStyles((theme) => ({

    timeFilterDrawer : {
        zIndex: theme.zIndex.drawer + 4,
    },
    timeFilterDrawerContent: {
        //marginLeft: 50,
        padding: 0,
        maxHeight: '50%',

        //marginBottom: bottomApBarHeight
    },
    accordionContent : {
        width:`calc(100% - 30px)`
    }
    })
)


function NotamAccordionItem(props) {

    const {notam} = props;
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Accordion style={{padding:0, width:`calc(100%)`}} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                classes={{
                    content : classes.accordionContent
                }}

            >
                {!expanded && <NotamHeaderHint notam={notam}/>}
                {expanded && <NotamHeader notam={notam}/>}

            </AccordionSummary>
            <AccordionDetails>
                <NotamBody notam={notam} />
            </AccordionDetails>
        </Accordion>

    )
}


function Notams(props) {
    const {NotamData} = props
    const classes = useStyles();

    return NotamData.map((notam, index)=> (
        <NotamAccordionItem notam={notam}/>
        ));

}

function NotamPopup(props) {

    const {setShowNotamDrawer, showNotamDrawer, NotamData} = props
    const classes = useStyles();
    const theme = useTheme();

    return (
        <Drawer
            //ModalProps={{ onBackdropClick: ()=> setShowNotamDrawer(false, [])} }
            anchor="bottom"
            variant="persistent"
            open={showNotamDrawer}
            className={classes.timeFilterDrawer}
            classes={{
                paper: classes.timeFilterDrawerContent,
            }}>
            <Container style={{maxWidth:'100%', padding:0}}>
                <Notams NotamData={NotamData} style={{padding:0}}/>
            </Container>
        </Drawer>
    )

}

export default NotamPopup;