import React from "react";
import history from './history';


import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import IconButton from "@material-ui/core/IconButton";
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import Container from '@material-ui/core/Container';
import Hidden from "@material-ui/core/Hidden";
import Tooltip from '@material-ui/core/Tooltip';
import SvgIcon from "@material-ui/core/SvgIcon";
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import Link from '@material-ui/core/Link';
import Box from "@material-ui/core/Box";
import Chip from '@material-ui/core/Chip';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Divider from '@material-ui/core/Divider';
import Emoji from "./emoji";

import { ReactComponent as DrawerIcon } from "./icons/drawer.svg";
import { ReactComponent as SettingIcon } from "./icons/cog.svg";
import { ReactComponent as FiltersIcon } from "./icons/filters.svg";

import SideBar from "./SidePane.js";
import Map from "./Map";
// import TextNotam from "./TextNotam";
import TimeFilter from "./TimeFilter";
import NotamPopup from "./NotamPopup"
// import LocationsFilter from "./AerodromFilter";
import NotamPane from "./NotamPane";

import { tsToUTCString } from './utils'
import {Button} from "@material-ui/core";

const drawerWidth = 450
const optionDrawerWidth = 210;
const filterIconSize = 50;
const bottomApBarHeight = 35;



const styles = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 3,
    // width: `calc(100% - ${drawerWidth}px)`,
  },
  bottomAppBar: {
    zIndex: theme.zIndex.drawer + 3,
    top:'auto',
    bottom:0,
    height: bottomApBarHeight,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.text.secondary
    // width: `calc(100% - ${drawerWidth}px)`,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  filterDrawer : {
    zIndex: theme.zIndex.drawer + 1,
  },
  filterDrawerContent: {
    marginLeft: optionDrawerWidth,
    padding: theme.spacing(3),
    height: 250,
  },
  timeFilterDrawer : {
    zIndex: theme.zIndex.drawer + 1,
  },
  timeFilterDrawerContent: {
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up('sm')]: {
      marginLeft: optionDrawerWidth,
      // marginRight: drawerWidth,
    },
    padding: theme.spacing(1),
    //marginBottom: bottomApBarHeight
  },
  aerodromFilterDrawer : {
    zIndex: theme.zIndex.drawer + 1,
  },
  aerodromFilterDrawerContent: {
    marginLeft: optionDrawerWidth,
    padding: theme.spacing(1),
  },
  drawer: {
    // [theme.breakpoints.up('sm')]: {
    // width: drawerWidth,
    // flexShrink: 0,
    // }
  },
  drawerPaper: {
    [theme.breakpoints.up('sm')]: {
      width: 450,
      flexShrink: 0,
    },
    width: '100%',
    // position: 'absolute',
    // top: 65
    // width: '100%',
    flexShrink: 0,

  },
  mobileDrawer: {
    width: '100%',
    flexShrink: 0,
  },
  mobileDrawerPaper: {
    width: '100%',
  },
  optionsMobileDrawer: {
    width: '100%',
    flexShrink: 0,
  },
  optionsMobileDrawerPaper: {
    width: '100%',
  },
  optionsDrawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  notamsDrawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  // drawerContainer: {
  //   overflow: 'auto',
  // },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    // backgroundColor: 'blue',
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(0),
    flexDirection: 'column'
  },
  consentButton: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
    },
    margin: theme.spacing(1),
  },
  consentSnackbar: {
    padding: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  donateButton: {
    backgroundColor: "#ffdb0c",
    color: theme.palette.common.black,
    borderRadius: 25,
    boxShadow: '0px 0px 8px 2px rgba(255, 219, 12, .3)',
    '&:hover': {
      backgroundColor: "#ffe345",
      color: theme.palette.common.black,
    },
    margin: theme.spacing(1),
  },
  donateButtonText: {
    paddingLeft: 10,
    paddingRight: 10
  },
  drawerButton : {
    padding: theme.spacing(1),
    marginLeft: 10,
    // [theme.breakpoints.up('sm')]: {
    //   display: 'none',
    // },
  },
  cogButton : {
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  optionsDrawer: {
    [theme.breakpoints.up('sm')]: {
      width: optionDrawerWidth,
      flexShrink: 0,
      padding: theme.spacing(0),
      overflowX: "hidden",
      zIndex: theme.zIndex.drawer + 2,
    }
  },
  optionsDrawerPaper: {
    width: optionDrawerWidth,
    overflowX: "hidden"
  },
  betaChip :{
    color : theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
    boxShadow: '4px 3px 5px 2px rgba(255, 105, 135, .3)',
  }
});


class App extends React.Component {

  constructor(props) {
    super(props);
  }




  state = {
    notams: [],
    CVFR_waypoints: [],
    IFR_waypoints: [],
    LSA_waypoints: [],
    _fetch: false,
    total: 0,
    available : 0,
    total_unfiltered : 0,
    popupState: [],
    selectedIndex: [],
    mobileOpen: false,
    optionsMobileOpen:false,
    filters: {
      vfr : true,
      ifr : true,
      enroute : true,
      aerodrome : true,
      navwarn : true,
      checklist : true
    },
    locationsFilterSelection: [],
    locationsFilter: [],
    routeFilterPane: false,
    timespan_start: this.getNow().getTime(),
    timespan_end: this.getNow().setHours(this.getNow().getHours() + 24),
    timeFilterDrawerState: false,
    locationsFilterDrawerState: false,
    showNotamDrawer: false,
    NotamDrawerData: [],
    loadingState : true,
    fetchError: false,
    showNotice: Boolean(!parseInt(process.env.REACT_APP_DEBUG_MODE)),
  }



  getNow(){
    return new Date(Date.now())
  }


  preQuery(){
    console.log('preQuery')
    this.setState({
      notams: [],
      total: [],
      loadingState: true
    }, () => {
      this.query()
    })
  }


  query(raw = '') {
    console.log('query')
    let API = process.env.REACT_APP_HOST_IP_ADDRESS
    //API = '192.168.1.103'; //to use from my iPhone this is my local machine

    let url = `https://${API}/api/v1.0/notams?process=true`;

    // if (process.env.REACT_APP_DEBUG_MODE) {
    //   url = `http://${API}/api/v1.0/notams?process=true`;
    // }


    /////////////////////////////////////
    // FILTERS LOGIC
    /////////////////////////////////////

    let flight_rules = ''

    if (this.state.filters.ifr) {
      flight_rules += '&traffic=I'
    }
    if (this.state.filters.vfr) {
      flight_rules += '&traffic=V'
    }
    if ((this.state.filters.ifr === false) &&
        (this.state.filters.vfr === false)) {
      flight_rules = '&traffic=X' // we will not get any traffic :)
    }
    if ((this.state.filters.ifr) &&
        (this.state.filters.vfr)) {
      flight_rules = '' // we will not filter traffic at all :)
    }

    url += flight_rules

    let scope = ''

    if (this.state.filters.aerodrome) {
      scope += '&scope=A'
    }
    if (this.state.filters.enroute) {
      scope += '&scope=E'
    }
    if (this.state.filters.navwarn) {
      scope += '&scope=W'
    }
    if (this.state.filters.checklist) {
      scope += '&scope=K'
    }

    if ((this.state.filters.aerodrome === false) &&
        (this.state.filters.enroute === false) &&
        (this.state.filters.navwarn === false) &&
        (this.state.filters.checklist === false)) {
      scope = '&scope=X' // we will not get any scopes :)
    }

    if ((this.state.filters.aerodrome) &&
        (this.state.filters.enroute) &&
        (this.state.filters.navwarn) &&
        (this.state.filters.checklist)) {
      scope = '' // we will not filter scopes :)
    }

    url += scope


    if (this.state.timespan_start) {
      url += '&timespan_start=' + parseInt(this.state.timespan_start / 1000)
    }
    if (this.state.timespan_end) {
      url += '&timespan_end=' + parseInt(this.state.timespan_end / 1000)
    }

    let locQuery = ''
    this.state.locationsFilter.map((loc) =>(
        locQuery += `&location=${loc}`
    ))

    url += locQuery

    /////////////////////////////////////
    /////////////////////////////////////


    // let s = tsToUTCString(this.state.timespan_start)
    // let e = tsToUTCString(this.state.timespan_end)
    //

    //console.log(process.env.REACT_APP_HOST_IP_ADDRESS)
    console.log('API query ---> ' + url)

    fetch(url)
        .then(this.handleErrors)
        .then((result) => result.json())
        .then((result) => {
          this.setState({
            notams: result.rows,
            _fetch: result.fetch['as-of'],
            total: result.rows.length,
            available : result.available,
            total_unfiltered : result.total_unfiltered,
            fetchError: false
          }, () => {
            this.afterQuery()
          })
        })

  }

  afterQuery() {
    // console.log("afterQuery - got total:")
    this.setState({
      loadingState: false
    })
    console.log(this.state.total ,' notams found')

  }


  handleErrors(response) {
    if (response.status == 401) { //UNAUTHORISED
      history.push('/login');
      history.go(0);
    }
    return response;
  }

  componentDidMount() {
    console.log('APP:componentDidMount')
    this.query()

  }
  //
  // componentWillMount() {
  //   // <a href="https://www.patreon.com/bePatron?u=22978936" data-patreon-widget-type="become-patron-button">Become a Patron!</a><script async src="https://c6.patreon.com/becomePatronButton.bundle.js"></script>
  //   const scriptTag = document.createElement("script");
  //   scriptTag.src = "https://c6.patreon.com/becomePatronButton.bundle.js";
  //   scriptTag.async = true;
  //   document.body.appendChild(scriptTag);
  // }

  // notamClicked(notam) {
  //   console.log(notam.id)
  //   this.setState({
  //     selectedIndex: notam.id,
  //   })
  // }


  toggleTimeFilterDrawer(state){
    console.log('toggleTimeFilterDrawer')
    console.log(state)
    this.setState({
      timeFilterDrawerState: !state
    });
  };


  handleOptionsDrawerToggle = booleanValue => () => {
    console.log('handleDrawerToggle')
    this.setState({
      optionsMobileOpen: booleanValue
    });
  };

  handleDrawerToggle = booleanValue => () => {
    console.log('handleDrawerToggle')
    this.setState({
      mobileOpen: booleanValue
    });
  };

  toggleSideFilter (filterName, filterValue)  {
    console.log('toggleSideFilter')
    //console.log(filterName)
    this.setState({
      [filterName]: !filterValue
    }, () => this.preQuery());



  };

  toggleRouteFilterPane (v)  {
    console.log('toggleRouteFilterPane')
    this.setState({
      routeFilterPane: !v
    });

  };

  setShowNotamDrawer (v, data)  {
    if (v != this.state.showNotamDrawer) {
      this.setState({
        showNotamDrawer: v,
        NotamDrawerData: data,
        selectedIndex: data.map(notam => notam.id)
      });
    }

  };


  setTimeSpanFilter(start, end){

    if(parseInt(start) != this.state.timespan_start || parseInt(end) != this.state.timespan_end) {

      console.log('a date was modifed! ' + start + ' ' +end)

      this.setState({
        timespan_start: parseInt(start),
        timespan_end: parseInt(end),
      }, () => this.preQuery())
    }

  }

  toggleLocationsFilterDrawer(state){
    console.log('toggleLocationsFilterDrawer')
    //console.log(state)
    var newState = !state
    this.setState({
      locationsFilterDrawerState: !state
    }, ()=>{
      if(newState === false){
        this.applyAerodromsFilter() //Apply the filter if we are closing the drawer
      } else {
        console.log('we just opend the drawers so ne need to do nothing')
      }
    });
  };


  // setLocationsFilter(locations){
  //   console.log('setLocationsFilter')
  //   // console.log('filter settings:')
  //   // console.log(this.state.locationsFilter)
  //   //
  //   // console.log('selection update:')
  //   // console.log(locations)
  //
  //   this.setState({
  //     locationsFilterSelection: locations //DICT
  //   })
  // }


  // applyAerodromsFilter(){
  //
  //   console.log('selection state:')
  //   console.log(this.state.locationsFilterSelection)
  //   console.log('filter settings:')
  //   console.log(this.state.locationsFilter)
  //
  //   if (this.state.locationsFilter === this.state.locationsFilterSelection) {
  //     console.log('no change in aerodrom filter')
  //     return;
  //   }
  //
  //   console.log('applyAerodromsFilter')
  //
  //   this.setState({
  //     locationsFilter: this.state.locationsFilterSelection
  //   }, () => this.preQuery())
  // }

  closeNotice = () => {
    console.log('close?')
    this.setState({
      showNotice: false
    })
  }


  setFilters(filters) {
    console.log(filters)
    this.setState( {
      filters: filters
    }, () => this.preQuery())
  }

  setClearAllFilters() {
    console.log('Clear all filters')
    this.setState({
      vfrFilter: false,
      ifrFilter: false,
      enrouteFilter: false,
      aerodromeFilter: false,
      navwarnFilter : false,
      // locationsFilterSelection: [],
      locationsFilter: [],
    }, () => this.preQuery())
  }


  setLocationsFilter(locations) {
    console.log(locations)
    this.setState({
      locationsFilter : locations
    },() => this.preQuery())
  }

  handleNotamClick(ntm){
    this.setState({
      selectedIndex : [ntm.id]
    })
  }

  render() {
    const {window,
          classes,
          CVFR_waypoints,
          IFR_waypoints,
          LSA_waypoints,
          locations,
          } = this.props

    const {
      notams,
      selectedIndex,
      filters,
      routeFilterPane,
      timeFilterDrawerState,
      locationsFilterDrawerState,
      timespan_start,
      timespan_end,
      total,
      available,
      total_unfiltered,
      showNotamDrawer,
      NotamDrawerData,
      locationsFilter,
      loadingState,
      _fetch,
      fetchError,
      showNotice
    } = this.state

    const container = window !== undefined ? () => window().document.body : undefined;



    const lastUpdate = tsToUTCString(_fetch*1000)


    console.log('APP RENDER')

    return (
        <div className={classes.root}>
          <CssBaseline/>


          <Backdrop className={classes.backdrop} open={loadingState}>
            <CircularProgress color="inherit" />
          </Backdrop>

          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>

              <IconButton
                  className={classes.cogButton}
                  color="inherit"
                  aria-label="Menu"
                  edge='start'
                  onClick={this.handleOptionsDrawerToggle(!this.state.optionsMobileOpen)}
                  //style={{marginRight: 5}}
              >
                <SvgIcon viewBox={'0 0 40 40'} style={{height: 40, width: 40}}>
                  <FiltersIcon/>
                </SvgIcon>

              </IconButton>

              <Typography variant="h6" noWrap className={classes.title}>
                LL NOTAM
              </Typography>

              <Button className={classes.donateButton}
                      target="_blank"
                      href="https://www.patreon.com/bePatron?u=22978936"
              >
                  <Box className={classes.donateButtonText} fontWeight="fontWeightBold"><Emoji symbol="💙️"/> תרומה  </Box>
              </Button>
              {/*<Chip size='small' label="PUBLIC BETA" className={classes.betaChip}/>*/}

              <IconButton
                    className={classes.drawerButton}
                    color="inherit"
                    aria-label="Menu"
                    edge='start'
                    onClick={this.handleDrawerToggle(!this.state.mobileOpen)}
                    //style={{marginLeft: 5}}
                >

                  <SvgIcon viewBox={'0 0 40 40'} style={{height: 40, width: 40}}>
                    <DrawerIcon/>
                  </SvgIcon>

                </IconButton>

            </Toolbar>
          </AppBar>


          <Snackbar
                    id='legalNotice'
                    open={showNotice}
                    autoHideDuration={60000000}
                    classes={classes.consentSnackbar}
                    message={
            <Container>
            <Typography variant={"h5"}>
              <Box fontWeight="fontWeightBold" > IMPORTANT </Box>
            </Typography>
              <Typography variant={"body"}>
                <p><Box fontWeight="fontWeightMedium" >
                FLIGHT OPERATIONS BASED ON INFORMATION ACQUIRED USING THIS APPLICATION WILL BE UNDER YOUR
                  RESPONSIBILITY
                </Box>
                </p>
                <p>
                  This application is informational in nature, and is not intended to replace
                  official aeronautical publications
                </p>
               <p>Information provided by this application may not be accurate,
                 it may be partial or may contain erroneous data</p>

                <p>
                  The data displayed in this application is provided as-is, without any expressed or implied warranty
                  - In no event will the authors be held liable for any damages arising from the use of this application</p>
              </Typography>

            </Container>

          }
                    action={
                      <Button
                          onClick={this.closeNotice}
                          key="accept"
                          size="large"
                        className={classes.consentButton}
                      >
                        I Accept
                      </Button>
                    }
            anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}>

          </Snackbar>


          <Drawer
              ModalProps={{ onBackdropClick: ()=> this.toggleTimeFilterDrawer(timeFilterDrawerState)} }
              anchor="bottom"
              variant="persistent"
              open={true}
              className={classes.timeFilterDrawer}
              classes={{
                paper: classes.timeFilterDrawerContent,
              }}
            >


            <TimeFilter toggleTimeFilterDrawer={this.toggleTimeFilterDrawer.bind(this)}
                        timeFilterDrawerState={timeFilterDrawerState}
                        timespan_start={timespan_start}
                        timespan_end={timespan_end}
                        setTimeSpanFilter={this.setTimeSpanFilter.bind(this)}
            />


          </Drawer>


          {/*<Drawer*/}
          {/*    ModalProps={{ onBackdropClick: ()=> this.toggleLocationsFilterDrawer(locationsFilterDrawerState)} }*/}
          {/*    anchor="left"*/}
          {/*    variant="persistent"*/}
          {/*    open={locationsFilterDrawerState}*/}
          {/*    className={classes.aerodromFilterDrawer}*/}
          {/*    classes={{*/}
          {/*      paper: classes.aerodromFilterDrawerContent,*/}
          {/*    }}>*/}

          {/*  <Toolbar/>*/}
          {/*  */}
          {/*</Drawer>*/}


          <NotamPopup
              showNotamDrawer={showNotamDrawer}
              setShowNotamDrawer={this.setShowNotamDrawer.bind(this)}
              NotamData={NotamDrawerData}
          />


          <nav className={classes.optionsDrawer}>
            <Hidden xsDown implementation="css">
              <Drawer className={classes.optionsDrawer}
                      variant="permanent"
                      anchor="left"
                      classes={{
                        paper: classes.optionsDrawerPaper,
                      }}>
                  <Toolbar />
                  <SideBar
                           toggleRouteFilterPane = {this.toggleRouteFilterPane.bind(this)}
                           toggleTimeFilterDrawer = {this.toggleTimeFilterDrawer.bind(this)}
                           toggleLocationsFilterDrawer = {this.toggleLocationsFilterDrawer.bind(this)}
                           timeFilterDrawerState ={timeFilterDrawerState}
                           locationsFilterDrawerState={locationsFilterDrawerState}
                           locations={locations}
                           locationsFilter={locationsFilter}
                           setLocationsFilter={this.setLocationsFilter.bind(this)}
                           filters={filters}
                           setFilters={this.setFilters.bind(this)}
                           routeFilterPane={routeFilterPane}


                  />
              </Drawer>
            </Hidden>
          </nav>

          <Hidden smUp implementation="css">
            <Drawer
                className={classes.optionsMobileDrawer}
                variant="temporary"
                anchor="left"
                classes={{
                  paper: classes.optionsMobileDrawerPaper,
                }}
                open={this.state.optionsMobileOpen}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                  onBackdropClick: this.handleOptionsDrawerToggle(! this.state.optionsMobileOpen)
                }}
                container={container}>
              <div className={classes.optionsDrawerHeader}>
                <IconButton onClick={this.handleOptionsDrawerToggle(! this.state.optionsMobileOpen)}>
                  <ChevronLeftIcon />
                </IconButton>
              </div>
              <Divider />
              <SideBar
                       toggleRouteFilterPane = {this.toggleRouteFilterPane.bind(this)}
                       toggleTimeFilterDrawer = {this.toggleTimeFilterDrawer.bind(this)}
                       toggleLocationsFilterDrawer = {this.toggleLocationsFilterDrawer.bind(this)}
                       timeFilterDrawerState ={timeFilterDrawerState}
                       locationsFilterDrawerState={locationsFilterDrawerState}
                       filters={filters}
                       setFilters={this.setFilters.bind(this)}
                       locations={locations}
                       locationsFilter={locationsFilter}
                       setLocationsFilter={this.setLocationsFilter.bind(this)}
                       routeFilterPane={routeFilterPane}
              />
            </Drawer>

          </Hidden>



          <main className={classes.content}>
            <div id={'map-wrapper'}>
              <Map
                  notams={notams}
                  cvfr_waypoints={CVFR_waypoints}
                  ifr_waypoints={IFR_waypoints}
                  lsa_waypoints={LSA_waypoints}
                  showNotamDrawer={showNotamDrawer}
                  setShowNotamDrawer={this.setShowNotamDrawer.bind(this)}
                  selectedIndex={selectedIndex}
                  setClearAllFilters={this.setClearAllFilters.bind(this)}
                  total={total}
                  available={available}
                  total_unfiltered={total_unfiltered}
                  lastUpdate={lastUpdate}
                  _fetch={_fetch}
              />

            </div>
          </main>

          {/*<nav className={classes.drawer}>*/}
          {/*  <Hidden xsDown implementation="css">*/}
              <Drawer
                  className={classes.drawer}
                  variant="temporary"
                  anchor="right"
                  open={this.state.mobileOpen}
                  classes={{
                    paper: classes.drawerPaper,
                  }}>
                {/*ModalProps={{*/}
                {/*keepMounted: true, // Better open performance on mobile.*/}
                {/*onBackdropClick: this.handleDrawerToggle(! this.state.mobileOpen)*/}
                {/*}}*/}

                  <div className={classes.notamsDrawerHeader}>
                    <IconButton onClick={this.handleDrawerToggle(! this.state.mobileOpen)}>
                      <ChevronRightIcon />
                    </IconButton>
                  </div>

                <NotamPane notams={notams} handleNotamClick={this.handleNotamClick.bind(this)}/>
              </Drawer>
            {/*</Hidden>*/}

          {/*</nav>*/}

            {/*<Hidden smUp implementation="css">*/}
            {/*  <Drawer*/}
            {/*      className={classes.mobileDrawer}*/}
            {/*      variant="temporary"*/}
            {/*      anchor="right"*/}
            {/*      classes={{*/}
            {/*        paper: classes.mobileDrawerPaper,*/}
            {/*      }}*/}
            {/*      open={this.state.mobileOpen}*/}
            {/*      ModalProps={{*/}
            {/*        keepMounted: true, // Better open performance on mobile.*/}
            {/*        onBackdropClick: this.handleDrawerToggle(! this.state.mobileOpen)*/}
            {/*      }}*/}
            {/*      container={container}>*/}

            {/*    <div className={classes.notamsDrawerHeader}>*/}
            {/*      <IconButton onClick={this.handleDrawerToggle(! this.state.mobileOpen)}>*/}
            {/*        <ChevronRightIcon />*/}
            {/*      </IconButton>*/}
            {/*    </div>*/}

            {/*    <NotamPane notams={notams} handleNotamClick={this.handleNotamClick.bind(this)}/>*/}

            {/*  </Drawer>*/}
            {/*</Hidden>*/}



          {/*<AppBar position="fixed" className={classes.bottomAppBar}>*/}
          {/*    <Typography variant="body2" noWrap className={classes.title}>*/}
          {/*      {total} Notams - Valid at {lastUpdate}*/}
          {/*    </Typography>*/}
          {/*</AppBar>*/}


          {/*<Snackbar*/}
          {/*    anchorOrigin={{*/}
          {/*      vertical: 'bottom',*/}
          {/*      horizontal: 'left',*/}
          {/*    }}*/}
          {/*    open={fetchError}*/}
          {/*    autoHideDuration={6000}*/}
          {/*    message={*/}
          {/*      <Container>*/}
          {/*        Communicating with the API*/}
          {/*        /!*<CircularProgress color="secondary" />*!/*/}
          {/*      </Container>*/}
          {/*    }*/}
          {/*/>*/}


        </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(App);

