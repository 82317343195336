import React from "react";

import App from "./App";

class Global extends React.Component {

    constructor(props) {
        super(props);
    }

    state = {
        locations: [],
        CVFR_waypoints: [],
        IFR_waypoints: [],
        LSA_waypoints: [],
    };

    locations_query() {
        let API = process.env.REACT_APP_HOST_IP_ADDRESS
        //API = '192.168.1.103'; //to use from my iPhone this is my local machine

        let url = `https://${API}/api/v1.0/notams/locations`

        // if (process.env.REACT_APP_DEBUG_MODE) {
        //     url = `http://${API}/api/v1.0/notams/locations`;
        //   }   

        console.log(url)

        fetch(url)
            .then((result) => result.json())
            .then((result) => {
                this.setState({
                    locations: result.rows,
                })
            })

    }

    waypoints_query(type) {
        let API = process.env.REACT_APP_HOST_IP_ADDRESS

        let url = `https://${API}/api/v1.0/waypoints/type/` + type
        console.log('waypoints_query - ' + type)
        
        // if (process.env.REACT_APP_DEBUG_MODE) {
        //     url = `http://${API}/api/v1.0/waypoints/type/` + type;
        //   } 

        fetch(url)
            .then((result) => result.json())
            .then((result) => {
                this.setState({
                    [type + '_waypoints']: result.rows,
                })
            })
    }


    componentDidMount() {
        this.locations_query()
        this.waypoints_query('CVFR')
        this.waypoints_query('IFR')
        this.waypoints_query('LSA')
    }

    render () {

        return (
            <App {...this.state} />
        )

    }
}

export default Global;
