import {makeStyles, useTheme, withStyles} from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";
import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Chip from "@material-ui/core/Chip";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import {ReactComponent as OBSTACLE} from "./icons/obstacle.svg";
import {ReactComponent as CLOSUER} from "./icons/no-entery.svg";
import {ReactComponent as WARNHEX} from "./icons/warning-hexagon.svg";
import {Button, Typography} from "@material-ui/core";
import Switch from '@material-ui/core/Switch';


function StyleMapping(input) {
    const theme = useTheme();

    const map = {
        expired: {backgroundColor: theme.palette.text.disabled, color:theme.palette.common.white},
        active: {backgroundColor: theme.palette.secondary.main, color:theme.palette.common.white},
        upcoming:  {backgroundColor: theme.palette.warning.main, color:theme.palette.common.white},
        I: {backgroundColor: theme.palette.primary.dark, color:theme.palette.common.white},
        V: {backgroundColor: theme.palette.info.light, color:theme.palette.common.white},
        IV: {backgroundColor: theme.palette.primary.dark, color:theme.palette.common.white},
        E: {backgroundColor: theme.palette.success.main, color:theme.palette.common.white},
        A: {backgroundColor: theme.palette.text.secondary, color:theme.palette.common.white},
        AE: {backgroundColor: theme.palette.success.main, color:theme.palette.common.white},
        AW: {backgroundColor: theme.palette.warning.main, color:theme.palette.common.white},
        W: {backgroundColor: theme.palette.warning.main, color:theme.palette.common.white},
    }

    var global = map[input] || {}
    global.marginRight = 10

    return global

}

const termsMapping = {
    I:'IFR',
    V: 'VFR',
    IV: 'IFR/VFR',
    E: 'Enroute',
    A: 'Aerodrome',
    AE: 'Aerodrom/Enroute',
    AW: 'Aerodrom/Warning',
    W: 'Nav Warning',
    K: 'Checklist'
}



const AntSwitch = withStyles((theme) => ({
    root: {
        width: 28,
        height: 16,
        padding: 0,
        display: 'flex',
    },
    switchBase: {
        padding: 2,
        color: theme.palette.grey[500],
        '&$checked': {
            transform: 'translateX(12px)',
            color: theme.palette.common.white,
            '& + $track': {
                opacity: 1,
                backgroundColor: theme.palette.success.main,
                borderColor: theme.palette.success.main,
            },
        },
    },
    thumb: {
        width: 12,
        height: 12,
        boxShadow: 'none',
    },
    track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.common.white,
    },
    checked: {},
}))(Switch);


const useStyles = makeStyles((theme) => ({

        decodeOptionsLabel : {
            paddingLeft: 10
        },

        decodeOptions : {
           boxSizing: "content-box",
           paddingRight: 8,
            maxWidth: 80,

        },

        decodeOptionsButton: {
            backgroundColor: theme.palette.info.main,
            color: theme.palette.info.contrastText
        },

        notam_h3 : {
            padding: theme.spacing(1),
            textAlign: 'left',
            overflowX: "hidden"
        },

        notam_h2 : {
            padding: theme.spacing(1),
            textAlign: 'left',
            color: theme.palette.text.primary,
            overflowX: "hidden",
            whiteSpace: "pre-line",
            width: '100%',
            overflowWrap: 'break-word'
        },

    chip : {
        margin: theme.spacing(0.5)
    }

    })
)

function tsToUTCString(timestamp) {
    if (timestamp < 10000000000) {
        // Looks like a timestamp in seconds and not ms
        timestamp *= 1000;
    }
    var d = new Date();
    d.setTime(timestamp);
    if (d.getUTCFullYear() >= 2038) {
        return "PERMANENT";
    } else {
        return d.toLocaleString("en-GB", {
            timeZone: "UTC", day: "2-digit", month: "2-digit", year: "numeric",
            hour: "2-digit", minute: "2-digit", timeZoneName: "short"
        });
    }
}

function NotamHeader(props) {
    const {notam} = props;
    return (
        <div>
            <Typography>
                {notam.itema}  {notam.series} {notam.number}/{notam.year}  NOTAM{notam.type}
                <br/>
                <NotamChips notam={notam}/>
            </Typography>

        </div>
    )
}

function NotamHeaderHint(props) {
    const {notam} = props;
    return (
        <div style={{width:'100%'}}>
            <Typography noWrap align='left'>
                {notam.iteme}
                <br/>
                <NotamChips notam={notam}/>
            </Typography>

        </div>
    )
}


function NotamIcon(notam) {

    if (notam.category === 'OBSTACLE') {
        return (
            <SvgIcon viewBox={'0 0 40 40'} style={{height: 40, width: 40, color: 'black'}}>
                <title>Obstacle</title>
                <OBSTACLE/>
            </SvgIcon>
        )
    } else if (notam.category === 'CLOSURE') {
        return (
            <SvgIcon viewBox={'0 0 40 40'} style={{height: 40, width: 40, color: 'black'}}>
                <title>Closure</title>
                <CLOSUER/>
            </SvgIcon>
        )
    } else {
        return (
            <SvgIcon viewBox={'0 0 40 40'} style={{height: 40, width: 40, color: 'black'}}>
                <title>Information</title>
                <WARNHEX/>
            </SvgIcon>
        )
    }

}


function NotamChips(props) {
    const {notam} = props;

    return (
        <div>
            <Chip style={StyleMapping(notam.status)} size='small' label={notam.status}/>
            <Chip style={StyleMapping(notam.scope.trim())} size='small' label={termsMapping[notam.scope.trim()]}/>
            <Chip style={StyleMapping(notam.traffic.trim())} size='small' label={termsMapping[notam.traffic.trim()]}/>
        </div>
    )
}

function NotamBody(props) {

    const {notam} = props
    const classes = useStyles();
    const theme = useTheme();

    const [decoded, setDecoded] = useState(true)

    const handleDecodeToggle = (event) => {
        setDecoded(event.target.checked)
    }

    return (

        <div style={{width:'100%'}}>

            <Grid container spacing={1}>
                <Grid item xs className={classes.notam_h3}>From:<br/>{tsToUTCString(notam.startvalidity)}</Grid>
                <Grid item xs className={classes.notam_h3}>To:<br/>{tsToUTCString(notam.endvalidity)}</Grid>
            </Grid>


            <Grid container spacing={1}>
                <Typography className={classes.notam_h2}>{decoded? notam.icao_expanded : notam.icao}</Typography>
            </Grid>

            <Grid container spacing={1} style={{marginLeft:15, paddingTop:5, width:'100%'}}>

                <FormControlLabel
                    style={{width:'100% !important'}}
                    control={
                        <Switch
                            checked={decoded}
                            onChange={handleDecodeToggle}
                            name="decodedSwitch"
                        />
                    }
                    label={<Typography style={{paddingLeft:10, width:'100%'}}>Decode notam</Typography>}
                />
            </Grid>


        </div>

    )

}

//
// function NotamContainer(props) {
//     const {notam} = props
//
//     return (
//
//         <div>
//
//             {/*{NotamIcon(notam)}*/}
//             {/*<br/>*/}
//             {/*{NotamHeader(notam)}*/}
//             <br/>
//             <NotamBody notam={notam}/>
//         </div>
//     )



// }

export {tsToUTCString, NotamHeader, NotamIcon, NotamBody, NotamChips, NotamHeaderHint, termsMapping};
