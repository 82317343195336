import React, {useEffect, useState} from "react";

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import SvgIcon from "@material-ui/core/SvgIcon";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import Collapse from "@material-ui/core/Collapse";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Tooltip from '@material-ui/core/Tooltip';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Box from "@material-ui/core/Box";



import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import { ReactComponent as IFRLogo } from "./icons/ai.svg";
import { ReactComponent as VFRLogo } from "./icons/vfr.svg";
import { ReactComponent as ENROUTELogo } from "./icons/enroute.svg";
import { ReactComponent as AERODROMELogo } from "./icons/aerodrome.svg";
import { ReactComponent as LocationLogo } from "./icons/location.svg";
import {ReactComponent as OBSTACLE} from "./icons/obstacle.svg";
import {ReactComponent as FWD24HRS} from "./icons/fwd-24-hours.svg";
import {ReactComponent as EYE} from "./icons/eye-fill.svg";
import {ReactComponent as EYECLOSED} from "./icons/eye-closed.svg";
import {ReactComponent as CIRCLE} from "./icons/circle.svg";
import {ReactComponent as FILLCIRCLE} from "./icons/filled-circle.svg";
import {ReactComponent as OK} from "./icons/ok.svg";
import {ReactComponent as CANCEL} from "./icons/cancel.svg";


import {Typography} from "@material-ui/core";
import {Icon} from "leaflet";


// const optionDrawerWidth = 100;
// const filterIconSize = 50;
const eyeIconSize = 25;

const useStyles = makeStyles((theme) => ({
    // optionsDrawer: {
    //     zIndex: theme.zIndex.drawer + 2,
    //     width: optionDrawerWidth,
    //     flexShrink: 0,
    //     padding: theme.spacing(0),
    //     overflowX: "hidden"
    // },
    // optionsDrawerPaper: {
    //     width: optionDrawerWidth,
    //     overflowX: "hidden"
    // },
    checkBoxIconChecked: {
        height: eyeIconSize,
        width: eyeIconSize,
        color: 'white',
    },
    checkBoxIconUnChecked: {
        height: eyeIconSize,
        width: eyeIconSize,
        color: 'black',
    },
    toggleRowChecked : {
        backgroundColor : theme.palette.secondary.main,
        color: 'white'
    },
    toggleRowUnChecked : {
        backgroundColor : theme.palette.background.paper,
        color: 'black'
    },
    nested: {
        paddingLeft: theme.spacing.unit * 4
    },
    subheader : {
        backgroundColor : theme.palette.background.paper,
        boxShadow: '4px 3px 5px 2px rgba(255, 105, 135, .3)',
    },
    subListControlRow : {
        paddingLeft : 0
    }

    })
)


function CheckIcon(props) {
    const {variant, checked} = props;

    if(checked) {
        switch (variant) {
            case 'eye':
                return <EYE/>
            case 'circle':
                return <OK/>
            default :
                return <div/>
        }
    } else {
        switch (variant) {
            case 'eye':
                return <EYECLOSED/>
            case 'circle':
                return <CIRCLE/>
            default :
                return <div/>
        }
    }
}


function CheckBox(props){

    const {callback, checked, variant} = props;

    const classes = useStyles();
    const theme = useTheme();

    return (
        <IconButton edge="end" aria-label="delete" onClick={callback}>
            <SvgIcon viewBox={'0 0 50 50'} className={checked ? classes.checkBoxIconChecked : classes.checkBoxIconUnChecked}>
                <CheckIcon checked={checked} variant={variant}/>
            </SvgIcon>
        </IconButton>
    )
}

function ToggleRow(props) {

    const {name, value, toggle, filterName, variant } = props;
    const [checked, setChecked] = React.useState(value)

    const classes = useStyles();
    const theme = useTheme();

    useEffect(() => {
        setChecked(props.value)
    },[props.value])


    const handleClick = () => {
        let newState = !checked
        setChecked(newState)
        toggle(filterName)
        //callback(newState)
    }

    return (
        <ListItem key={'0'} className={checked ? classes.toggleRowChecked: classes.toggleRowUnChecked} >
            <ListItemText primary={
                <Typography>
                    <Box fontWeight="fontWeightMedium">
                        {name}
                    </Box>
                </Typography>
            } />
            <ListItemSecondaryAction>
                <CheckBox callback={handleClick} checked={checked} variant={variant}/>
            </ListItemSecondaryAction>
        </ListItem>
    )

}


function SideBar(props) {
    const {
        locations,
        filters,
        setFilters,
        locationsFilter,
        setLocationsFilter,
    } = props;



    const [locationSublist, setLocationSublist] = useState(false)

    const [locationsState, setLocationsState] = useState([...locationsFilter])
    const [locationsDirty, setLocationsDirty] = useState(false)
    const [locationsIsFiltering, setlocationsIsFiltering] = useState(locationsState.length === 0 ? false : true)

    const classes = useStyles();
    const theme = useTheme();

    const equalsIgnoreOrder = (a, b) => {
        if (a.length !== b.length) return false;
        const uniqueValues = new Set([...a, ...b]);
        for (const v of uniqueValues) {
            const aCount = a.filter(e => e === v).length;
            const bCount = b.filter(e => e === v).length;
            if (aCount !== bCount) return false;
        }
        return true;
    }

    useEffect(() => {
        setLocationsState([...props.locationsFilter])
    },[props.locationsFilter])

    useEffect(() => {
        // every change of location selection this method will evaluate
        // if the selection is different then what the current filter is configured too
        // if its different we are 'dirty' - this will allow the user to apply the new selection

        if(!equalsIgnoreOrder(locationsState,locationsFilter)){
            setlocationsIsFiltering(locationsState.length === 0 ? false : true)
            setLocationsFilter(locationsState)
        }

        // if(!equalsIgnoreOrder(locationsState,locationsFilter)){
        //     setLocationsDirty(true)
        // } else {
        //     setLocationsDirty(false)
        // }

    }, [locationsState]);

    const toggleFilter = (f) => {
        filters[f] = !filters[f]
        setFilters(filters)
    }

    const handleLocation = (loc) => {

        if (!locationsState.includes(loc)){
            let temp = locationsState
            temp.push(loc)
            setLocationsState(temp.map(x=>x))
        } else {
            setLocationsState(locationsState.filter(item => item !== loc))
        }
    }

    const handleLocationReset = () => {
        setLocationsState([])
    }

    const handleLocationRestore = () => {
        setLocationsState([...props.locationsFilter])
    }

    const handleLocationCommit = () => {
        setLocationsFilter(locationsState)
    }

    return (
            <List>

                <ListSubheader component="div" id="nested-list-subheader" className={classes.subheader}>
                    Filters
                </ListSubheader>

                <Divider/>
                <ToggleRow name={'IFR'} value={filters.ifr} toggle={toggleFilter} filterName='ifr' variant={'eye'}/>
                <Divider/>
                <ToggleRow name={'VFR'} value={filters.vfr} toggle={toggleFilter} filterName='vfr' variant={'eye'}/>
                <Divider/>
                <ToggleRow name={'AERODROME'} value={filters.aerodrome} toggle={toggleFilter} filterName='aerodrome' variant={'eye'}/>
                <Divider/>
                <ToggleRow name={'ENROUTE'} value={filters.enroute} toggle={toggleFilter} filterName='enroute' variant={'eye'}/>
                <Divider/>
                <ToggleRow name={'NAV-WARN'} value={filters.navwarn} toggle={toggleFilter} filterName='navwarn' variant={'eye'}/>
                <Divider/>
                <ToggleRow name={'CHECKLIST'} value={filters.checklist} toggle={toggleFilter} filterName='checklist' variant={'eye'}/>


                <Divider/>

                <ListSubheader component="div" id="nested-list-subheader" className={classes.subheader}>
                    Locations
                </ListSubheader>


                <ListItem
                    key={'1000'}
                    className={classes.subListControlRow}
                >
                    {locationSublist ? (
                        <IconButton onClick={() => setLocationSublist(!locationSublist)}
                                    >
                            <ExpandLess />
                        </IconButton>
                    ) : (
                        <IconButton onClick={() => setLocationSublist(!locationSublist)}
                                    >
                            <ExpandMore />
                        </IconButton>
                    )}

                    <ListItemText primary={
                    <Typography>
                        <Box fontWeight="fontWeightMedium">
                            AD/FIR
                        </Box>
                    </Typography>}
                                  />

                    {locationsIsFiltering
                        ?
                        (<div>
                            <IconButton edge="end" aria-label="delete" onClick={handleLocationReset}>
                                <SvgIcon viewBox={'0 0 50 50'} className={classes.checkBoxIconUnChecked}>
                                    <CANCEL/>
                                </SvgIcon>
                            </IconButton>
                        </div>)
                        :
                        (<div/>)
                    }

                    {/*{locationsDirty*/}
                    {/*    ?*/}
                    {/*    (<div>*/}
                    {/*        <IconButton edge="end" aria-label="delete" onClick={handleLocationRestore}>*/}
                    {/*            <SvgIcon viewBox={'0 0 50 50'} className={classes.checkBoxIconUnChecked}>*/}
                    {/*                <CANCEL/>*/}
                    {/*            </SvgIcon>*/}
                    {/*        </IconButton>*/}
                    {/*        <IconButton edge="end" aria-label="delete" onClick={handleLocationCommit}>*/}
                    {/*            <SvgIcon viewBox={'0 0 50 50'} className={classes.checkBoxIconUnChecked}>*/}
                    {/*                <OK/>*/}
                    {/*            </SvgIcon>*/}
                    {/*        </IconButton>*/}
                    {/*    </div>)*/}
                    {/*    :*/}
                    {/*    (<div/>)*/}
                    {/*}*/}


                </ListItem>

                <Collapse
                    key={'1001'}
                    component="li"
                    in={locationSublist}
                    timeout="auto"
                    unmountOnExit
                >
                    <List disablePadding>
                        {locations.map(
                            (loc, index) => {
                                return (

                                    <ToggleRow name={loc} value={locationsState.includes(loc)} toggle={handleLocation} filterName={loc} variant={'circle'}/>

                                );
                            }
                        )}
                    </List>

                </Collapse>


            </List>

    )
}

export default SideBar;