import React from "react";

import Container from "@material-ui/core/Container";
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { NotamHeader, NotamIcon, NotamBody} from './utils'




function TextNotam(props) {
    const {notam, id, onClick} = props;


    return (
        <div>
            <ListItem
                      key={id}
                      alignItems="flex-start"
                      onClick={() => onClick(notam)}>

                {/*<ListItemIcon>*/}
                {/*        {NotamIcon(notam)}*/}
                {/*</ListItemIcon>*/}

                <ListItemText primary={<NotamHeader notam={notam}/>}
                              secondary={
                                    <NotamBody notam={notam} />
                                }
                />
            </ListItem>
            <Divider variant="fullWidth" component="hr"/>
        </div>
    )

}

export default TextNotam;
