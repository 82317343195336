import React, {useEffect} from "react";

import { makeStyles, useTheme, ThemeProvider } from '@material-ui/core/styles';

import Grid from "@material-ui/core/Grid";
import {Button, Container, Typography} from "@material-ui/core";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { IconButton, InputAdornment } from "@material-ui/core";
import { TextField } from '@material-ui/core';
import { Input, TextFieldProps } from "@material-ui/core";

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import { DateTime, Settings } from 'luxon';
import {  DateTimePicker } from "@material-ui/pickers";
import Box from "@material-ui/core/Box";

import { ReactComponent as SettingIcon } from "./icons/cog.svg";
import SvgIcon from "@material-ui/core/SvgIcon";
import {ReactComponent as StartIcon} from "./icons/start.svg";
import {ReactComponent as EndIcon} from "./icons/end.svg";
import {ReactComponent as TimeSearchIcon} from "./icons/calender-view.svg";

import { createMuiTheme } from "@material-ui/core";

Settings.defaultZoneName = "UTC";

const useStyles = makeStyles((theme) => ({
        // textField: {
        //     marginLeft: theme.spacing(1),
        //     marginRight: theme.spacing(1),
        //     width: 200,
        // },
        clocksContainer : {
            padding: theme.spacing(1),
            //backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white
        },
        clock : {
            maxWidth: 'max-content',
            width: 135,
        },
        timeIcon: {
            height: 30,
            width: 30,
            color: theme.palette.common.white,
            position : 'relative',
            top: 5
        },
        timeIconItem : {
            paddingLeft : '20px !important',
        },
        underline: {

            color: theme.palette.common.white,
            borderBottom: theme.palette.common.white,
            '&:after': {
                borderBottom: `2px solid ${theme.palette.common.white}`,
            },
            '&:focused::after': {
                borderBottom: `2px solid ${theme.palette.common.white}`,
            },
            '&:error::after': {
                borderBottom: `2px solid ${theme.palette.common.white}`,
            },
            '&:before': {
                borderBottom: `1px solid ${theme.palette.common.white}`,
            },
            '&:hover:not($disabled):not($focused):not($error):before': {
                borderBottom: `2px solid ${theme.palette.common.white} !important`,
            },
            '&$disabled:before': {
                borderBottom: `1px dotted ${theme.palette.common.white}`,
            },
        },
})
)



const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: {  // primary color
            contrastText: "#FFFFFF",
            dark: "#000000",
            main: "#000000",  // black
            light: "#000000"
            }

        }
    }
);


function TimeFilter(props) {
    const {timespan_start, timespan_end, setTimeSpanFilter} = props
    const classes = useStyles();
    const theme = useTheme();

    const [StartDate, setStartDate] = React.useState(DateTime.fromMillis(timespan_start));
    const [EndDate, setEndDate] = React.useState(DateTime.fromMillis(timespan_end));


    useEffect(()=> {
        setTimeSpanFilter(StartDate.toMillis(), EndDate.toMillis())
    }, [StartDate, EndDate])

    const handleStartChange = value => {
        console.log(value + ' START')
        setStartDate(value)
    }

    const handleEndChange = value => {
        console.log(value + ' END')
        if (value <= StartDate) {
            let v = new Date(StartDate.toMillis())
            v.setHours(StartDate.plus({hours: 24}))
            console.log('Can not have the end smaller then the start! - set the End to')
            console.log(v + ' <<')
            return;
        }
        setEndDate(value)
    }


    return (
        <Container className={classes.clocksContainer}>

            <Grid container spacing={1} justify='center'>
                <Grid item>
                    <SvgIcon viewBox={'0 0 40 40'} className={classes.timeIcon}>
                        <TimeSearchIcon/>
                    </SvgIcon>
                </Grid>


                <MuiPickersUtilsProvider utils={LuxonUtils}>
                    <ThemeProvider theme={defaultMaterialTheme}>
                    <Grid item className={classes.clock}>

                            <DateTimePicker
                                //variant="inline"
                                ampm={false}
                                //inputVariant="filled"
                                //label="FROM"
                                //label="Start span"
                                value={StartDate}
                                onChange={handleStartChange}
                                onError={console.log}
                                showTodayButton
                                format="dd/MM HH:mm"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Typography style={{marginRight: 2}}>
                                                <Box fontWeight="fontWeightBold" fontSize={16} >
                                                FM
                                                </Box>
                                            </Typography>
                                        </InputAdornment>
                                    ),
                                    style:{
                                        color: 'white'
                                    },
                                    size : 'small',
                                    className : classes.underline
                                }
                                }
                            />

                    </Grid>



                    <Grid item className={classes.clock}>
                        {/*<ThemeProvider theme={materialTheme}>*/}
                        <DateTimePicker
                            // TextFieldComponent={renderInput}
                            //variant="inline"
                            ampm={false}
                            //inputVariant="filled"
                            //label="TO"
                            value={EndDate}
                            onChange={handleEndChange}
                            onError={console.log}
                            showTodayButton
                            format="dd/MM HH:mm"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">

                                        <Typography style={{marginRight: 2}}>
                                            <Box fontWeight="fontWeightBold" fontSize={16} >
                                                TO
                                            </Box>
                                        </Typography>

                                    </InputAdornment>
                                ),
                                style:{
                                    color: 'white'
                                },
                                size : 'small',
                                className : classes.underline
                            }}
                        />
                        {/*</ThemeProvider>*/}
                    </Grid>
                    </ThemeProvider>
                </MuiPickersUtilsProvider>


                <Grid item style={{paddingLeft: 0}}>
                    <Typography style={{marginTop: 3}}>
                        <Box fontWeight="fontWeightBold" fontSize={18} >
                            UTC
                        </Box>
                    </Typography>
                </Grid>

            </Grid>

        </Container>
    )

}

export default TimeFilter;