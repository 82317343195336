import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import Container from '@material-ui/core/Container';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Image from 'material-ui-image';
import Emoji from "./emoji";
import {Avatar} from "@material-ui/core";
import AR_Logo from "./icons/AR_logo.png";
import history from './history';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        padding: theme.spacing(1),
    },
    mainContainer :{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: theme.spacing(3),
        backgroundColor: theme.palette.background.paper
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    })
)


export default function Login() {

    const classes = useStyles();
    const theme = useTheme();

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <Container className={classes.mainContainer}>

                {/*<Container>*/}
                    <Typography variant={"h5"}>
                        <Box fontWeight="fontWeightBold" > Welcome to Israel Notam <Emoji symbol="👋"/> </Box>
                    </Typography>
                    <Typography variant={"body"}>
                        <p><Box fontWeight="fontWeightMedium" >
                            Please use your Autorouter account to login
                        </Box>
                        </p>
                        <p>
                            Autorouter account is free of charge, and will grant you access to use this application.
                        </p>
                        <p>
                            If you don't have an account, the link below will take you to a page where you can create one.
                        </p>

                    </Typography>

                {/*</Container>*/}



                <Button
                    variant="outlined"
                    onClick={() => { history.push('auth/autorouter/authorize'); history.go(0); }}
                    startIcon={<Avatar className={classes.large}  variant="square" src={AR_Logo} />}
                >
                    Login with Autorouter
                </Button>
            </Container>
        </div>
    );
}
